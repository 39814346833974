<script setup lang="ts">
const { t } = useT();
const isGuest = useIsGuest();
const { prizeDropsData: coinDropsData } = useAppPrizeDrops();
const { refresh } = useAppInitData({ immediate: false });

const handleClick = () => {
	navigateTo("/coin-drops");
};

const handleTimer = () => {
	setTimeout(() => {
		refresh();
	}, 500);
};
</script>

<template>
	<MPromotionWrapper
		type="coinDrops"
		:title="t('Prize Pool')"
		image="nuxt-img/promotions/coin-drops.png"
		:buttonName="isGuest ? t('Register & play') : t('Start playing')"
		@click-card="handleClick"
	>
		<MPromotionCoins :entries="1400000" bigEntries />

		<MCounterBadgeGroup
			v-if="coinDropsData?.general?.finishedAt"
			:timestamp="coinDropsData?.general?.finishedAt ?? ''"
			:badgeText="t('Limited Offer')"
			@on-timer-end="handleTimer"
		/>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.box-progress {
	width: 100%;
	margin: 12px 0 8px;
}
</style>
